<template>
  <div>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container id="containerFiltering" class="container-filtering">
      <v-row align="center">
        <!-- Column Select Calendar From -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendarFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                label="From"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              @input="modalCalendarFrom = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Select Calendar To -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendarTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                label="To"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="dateTo" @input="modalCalendarTo = false">
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn
            class="btnExportExcelQQ"
            color="green"
            elevation="2"
            @click="exportReport('excel')"
          >
            <v-icon color="white" center dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- Section Result Generated Report (TABLE) -->
    <v-container class="container-filter">
      <!-- Specific Container -->
      <div>
        <template>
          <v-container fluid>
            <v-row align="center">
              <!-- <v-select
                        v-on:change="selectStation"
                        :items="station"
                        label="Station"
                        multiple
                        chips
                        hint="This station can be one or more than one selected"
                        persistent-hint
                        outlined
                        ></v-select> -->
              <v-autocomplete
                v-model="stationSelected"
                :items="station"
                label="Station"
                multiple
                chips
                hint="This station can be one or more than one selected"
                persistent-hint
                outlined
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleStation">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          stationSelected.length > 0 ? 'indigo darken-4' : ''
                        "
                      >
                        {{ iconStations }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-row>
            <v-row align="center" class="mt-5">
              <v-autocomplete
                v-model="parameterSelected"
                :items="parameter"
                label="Parameter"
                multiple
                chips
                hint="This parameter can be one or more than one selected"
                persistent-hint
                outlined
                :disabled="disabledInputParam && checkedAllStations == 0"
                :loading="loadingInputParam"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleParams">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          parameterSelected.length > 0 ? 'indigo darken-4' : ''
                        "
                      >
                        {{ iconParams }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <!-- <template v-slot:append-item>
                                    <v-divider class="mb-2"></v-divider>
                                    <v-list-item disabled>
                                    <v-list-item-avatar color="grey lighten-3">
                                        <v-icon>
                                        mdi-food-apple
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content v-if="checkedAllParams">
                                        <v-list-item-title>
                                        Holy smokes, someone call the fruit police!
                                        </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-content v-else-if="checkedSomeParams">
                                        <v-list-item-title>
                                        Fruit Count
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                        {{ parameterSelected.length }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-content v-else>
                                        <v-list-item-title>
                                        How could you not like fruit?
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                        Go ahead, make a selection above!
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                </template> -->
              </v-autocomplete>
            </v-row>
          </v-container>
        </template>

        <!-- Button Query -->
        <v-btn
          class="ma-2 white--text primary"
          solo
          dense
          normal
          @click="generateTable"
          :disabled="disableGenerateButton"
        >
          Standard Query
          <v-icon right dark big>
            mdi-table-arrow-up
          </v-icon>
        </v-btn>

        <!-- Button Param Graph -->
        <v-btn
          class="ma-2 white--text primary"
          solo
          dense
          normal
          @click="generateChart"
          :disabled="disableGenerateButton"
        >
          Parameter Graph
          <v-icon right dark big>
            mdi-chart-areaspline
          </v-icon>
        </v-btn>
      </div>
    </v-container>

    <!-- Section Result Generated Report (Show Table) -->
    <v-container class="container-result-table" align="center">
      <v-subheader class="subTitle fontSubTitle-blue">
        <!-- Data For {{displayParameterSelected}} ({{displayStationSelected}}) {{dateFrom}} To {{dateTo}} -->
        Data For ({{ displayStation.join(", ") }}) From
        {{ this.convertDateFormat(dateFrom) }} To
        {{ this.convertDateFormat(dateTo) }}
      </v-subheader>

      <div>
        <!-- Table -->
        <v-data-table
          :headers="dtheader"
          :items="dtbody"
          :header-props="{ sortIcon: null }"
          class="elevation-1 tableQueryOptions headerDtSarawak"
          :items-per-page="10"
          fixed-header
          :custom-sort="customSort"
          :loading="this.loadingTable"
          :loading-text="this.loadingText"
        >
          <!-- <template v-slot:header>
                    <thead class="header-colspan">
                        <tr>
                        <th>TIME</th>
                        <th>WQI</th>
                        <th colspan="3">DO</th>
                        <th colspan="2">BOD</th>
                        <th colspan="2">COD</th>
                        <th colspan="2">NH3N</th>
                        <th colspan="2">TSS</th>
                        <th colspan="2">pH</th>
                        <th>SpCond</th>
                        <th>Salinity</th>
                        <th>Temp</th>
                        <th>Turb</th>
                        <th>NH4</th>
                        <th>Solar Battery</th>
                        </tr>
                    </thead>
                    </template> -->
          <template v-slot:[`item.WQI`]="{ item }">
            <v-chip
              :color="getColor(item.WQI)"
              dark
              :class="getFontColor(item.WQI)"
              class="text-bold"
            >
              {{ item.WQI }}
            </v-chip>
          </template>

          <template v-slot:[`item.datetime_a`]="{ item }">
            <span v-html="item.datetime_a"></span>
          </template>

          <!-- Param Flag -->
          <template v-slot:[`item.DO_SAT`]="{ item }">
            <span v-if="item.DOSAT_FLAG == null">{{ item.DO_SAT }}</span>
            <br v-if="item.DOSAT_FLAG == null" />
            <font color="red"
              ><b>{{ item.DOSAT_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.DO_CON`]="{ item }">
            <span v-if="item.DOCON_FLAG == null">{{ item.DO_CON }}</span>
            <br v-if="item.DOCON_FLAG == null" />
            <font color="red"
              ><b>{{ item.DOCON_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.BOD`]="{ item }">
            <span v-if="item.BOD_FLAG == null">{{ item.BOD }}</span>
            <br v-if="item.BOD_FLAG == null" />
            <font color="red"
              ><b>{{ item.BOD_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.COD`]="{ item }">
            <span v-if="item.COD_FLAG == null">{{ item.COD }}</span>
            <br v-if="item.COD_FLAG == null" />
            <font color="red"
              ><b>{{ item.COD_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.NH3N`]="{ item }">
            <span v-if="item.NH3N_FLAG == null">{{ item.NH3N }}</span>
            <br v-if="item.NH3N_FLAG == null" />
            <font color="red"
              ><b>{{ item.NH3N_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.TSS`]="{ item }">
            <span v-if="item.TSS_FLAG == null">{{ item.TSS }}</span>
            <br v-if="item.TSS_FLAG == null" />
            <font color="red"
              ><b>{{ item.TSS_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.pH`]="{ item }">
            <span v-if="item.pH_FLAG == null">{{ item.pH }}</span>
            <br v-if="item.pH_FLAG == null" />
            <font color="red"
              ><b>{{ item.pH_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item._4_4_DDT`]="{ item }">
            <span v-if="item._4_4_DDT_FLAG == null">{{ item._4_4_DDT }}</span>
            <br v-if="item._4_4_DDT_FLAG == null" />
            <font color="red"
              ><b>{{ item._4_4_DDT_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.AG`]="{ item }">
            <span v-if="item.AG_FLAG == null">{{ item.AG }}</span>
            <br v-if="item.AG_FLAG == null" />
            <font color="red"
              ><b>{{ item.AG_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.AL`]="{ item }">
            <span v-if="item.AL_FLAG == null">{{ item.AL }}</span>
            <br v-if="item.AL_FLAG == null" />
            <font color="red"
              ><b>{{ item.AL_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.ALDRIN_DIELDRIN`]="{ item }">
            <span v-if="item.ALDRIN_DIELDRIN_FLAG == null">{{
              item.ALDRIN_DIELDRIN
            }}</span>
            <br v-if="item.ALDRIN_DIELDRIN_FLAG == null" />
            <font color="red"
              ><b>{{ item.ALDRIN_DIELDRIN_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.AS`]="{ item }">
            <span v-if="item.AS_FLAG == null">{{ item.AS }}</span>
            <br v-if="item.AS_FLAG == null" />
            <font color="red"
              ><b>{{ item.AS_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.B`]="{ item }">
            <span v-if="item.B_FLAG == null">{{ item.B }}</span>
            <br v-if="item.B_FLAG == null" />
            <font color="red"
              ><b>{{ item.B_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.BA`]="{ item }">
            <span v-if="item.BA_FLAG == null">{{ item.BA }}</span>
            <br v-if="item.BA_FLAG == null" />
            <font color="red"
              ><b>{{ item.BA_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.BHC`]="{ item }">
            <span v-if="item.BHC_FLAG == null">{{ item.BHC }}</span>
            <br v-if="item.BHC_FLAG == null" />
            <font color="red"
              ><b>{{ item.BHC_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.BR`]="{ item }">
            <span v-if="item.BR_FLAG == null">{{ item.BR }}</span>
            <br v-if="item.BR_FLAG == null" />
            <font color="red"
              ><b>{{ item.BR_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CA`]="{ item }">
            <span v-if="item.CA_FLAG == null">{{ item.CA }}</span>
            <br v-if="item.CA_FLAG == null" />
            <font color="red"
              ><b>{{ item.CA_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CCE`]="{ item }">
            <span v-if="item.CCE_FLAG == null">{{ item.CCE }}</span>
            <br v-if="item.CCE_FLAG == null" />
            <font color="red"
              ><b>{{ item.CCE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CD`]="{ item }">
            <span v-if="item.CD_FLAG == null">{{ item.CD }}</span>
            <br v-if="item.CD_FLAG == null" />
            <font color="red"
              ><b>{{ item.CD_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CDOM`]="{ item }">
            <span v-if="item.CDOM_FLAG == null">{{ item.CDOM }}</span>
            <br v-if="item.CDOM_FLAG == null" />
            <font color="red"
              ><b>{{ item.CDOM_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CHLORDANE`]="{ item }">
            <span v-if="item.CHLORDANE_FLAG == null">{{ item.CHLORDANE }}</span>
            <br v-if="item.CHLORDANE_FLAG == null" />
            <font color="red"
              ><b>{{ item.CHLORDANE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CHLOROPHYLL_A`]="{ item }">
            <span v-if="item.CHLOROPHYLL_A_FLAG == null">{{
              item.CHLOROPHYLL_A
            }}</span>
            <br v-if="item.CHLOROPHYLL_A_FLAG == null" />
            <font color="red"
              ><b>{{ item.CHLOROPHYLL_A_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CL`]="{ item }">
            <span v-if="item.CL_FLAG == null">{{ item.CL }}</span>
            <br v-if="item.CL_FLAG == null" />
            <font color="red"
              ><b>{{ item.CL_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CL2`]="{ item }">
            <span v-if="item.CL2_FLAG == null">{{ item.CL2 }}</span>
            <br v-if="item.CL2_FLAG == null" />
            <font color="red"
              ><b>{{ item.CL2_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CN`]="{ item }">
            <span v-if="item.CN_FLAG == null">{{ item.CN }}</span>
            <br v-if="item.CN_FLAG == null" />
            <font color="red"
              ><b>{{ item.CN_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CO2`]="{ item }">
            <span v-if="item.CO2_FLAG == null">{{ item.CO2 }}</span>
            <br v-if="item.CO2_FLAG == null" />
            <font color="red"
              ><b>{{ item.CO2_FLAG }}</b></font
            >
          </template>

          <!-- <template v-slot:[`item.CO2`]="{ item }">
                        {{ item.CO2 }}
                        <br v-if="item.CO2_FLAG == null">
                        <font color='red'><b>{{ item.CO2_FLAG }}</b></font>
                    </template> -->

          <template v-slot:[`item.COLOUR`]="{ item }">
            <span v-if="item.COLOUR_FLAG == null">{{ item.COLOUR }}</span>
            <br v-if="item.COLOUR_FLAG == null" />
            <font color="red"
              ><b>{{ item.COLOUR_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CR`]="{ item }">
            <span v-if="item.CR_FLAG == null">{{ item.CR }}</span>
            <br v-if="item.CR_FLAG == null" />
            <font color="red"
              ><b>{{ item.CR_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CR_III`]="{ item }">
            <span v-if="item.CR_III_FLAG == null">{{ item.CR_III }}</span>
            <br v-if="item.CR_III_FLAG == null" />
            <font color="red"
              ><b>{{ item.CR_III_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CR_IV`]="{ item }">
            <span v-if="item.CR_IV_FLAG == null">{{ item.CR_IV }}</span>
            <br v-if="item.CR_IV_FLAG == null" />
            <font color="red"
              ><b>{{ item.CR_IV_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.CU`]="{ item }">
            <span v-if="item.CU_FLAG == null">{{ item.CU }}</span>
            <br v-if="item.CU_FLAG == null" />
            <font color="red"
              ><b>{{ item.CU_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.DEPTH`]="{ item }">
            <span v-if="item.DEPTH_FLAG == null">{{ item.DEPTH }}</span>
            <br v-if="item.DEPTH_FLAG == null" />
            <font color="red"
              ><b>{{ item.DEPTH_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.DOC`]="{ item }">
            <span v-if="item.DOC_FLAG == null">{{ item.DOC }}</span>
            <br v-if="item.DOC_FLAG == null" />
            <font color="red"
              ><b>{{ item.DOC_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.E_COLI`]="{ item }">
            <span v-if="item.E_COLI_FLAG == null">{{ item.E_COLI }}</span>
            <br v-if="item.E_COLI_FLAG == null" />
            <font color="red"
              ><b>{{ item.E_COLI_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.EC`]="{ item }">
            <span v-if="item.EC_FLAG == null">{{ item.EC }}</span>
            <br v-if="item.EC_FLAG == null" />
            <font color="red"
              ><b>{{ item.EC_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.ENDOSULFAN`]="{ item }">
            <span v-if="item.ENDOSULFAN_FLAG == null">{{
              item.ENDOSULFAN
            }}</span>
            <br v-if="item.ENDOSULFAN_FLAG == null" />
            <font color="red"
              ><b>{{ item.ENDOSULFAN_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.F`]="{ item }">
            <span v-if="item.F_FLAG == null">{{ item.F }}</span>
            <br v-if="item.F_FLAG == null" />
            <font color="red"
              ><b>{{ item.F_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.FC`]="{ item }">
            <span v-if="item.FC_FLAG == null">{{ item.FC }}</span>
            <br v-if="item.FC_FLAG == null" />
            <font color="red"
              ><b>{{ item.FC_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.FDOM`]="{ item }">
            <span v-if="item.FDOM_FLAG == null">{{ item.FDOM }}</span>
            <br v-if="item.FDOM_FLAG == null" />
            <font color="red"
              ><b>{{ item.FDOM_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.FE`]="{ item }">
            <span v-if="item.FE_FLAG == null">{{ item.FE }}</span>
            <br v-if="item.FE_FLAG == null" />
            <font color="red"
              ><b>{{ item.FE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.FLOWRATE`]="{ item }">
            <span v-if="item.FLOWRATE_FLAG == null">{{ item.FLOWRATE }}</span>
            <br v-if="item.FLOWRATE_FLAG == null" />
            <font color="red"
              ><b>{{ item.FLOWRATE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.GROSS_ALPHA`]="{ item }">
            <span v-if="item.GROSS_ALPHA_FLAG == null">{{
              item.GROSS_ALPHA
            }}</span>
            <br v-if="item.GROSS_ALPHA_FLAG == null" />
            <font color="red"
              ><b>{{ item.GROSS_ALPHA_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.GROSS_BETA`]="{ item }">
            <span v-if="item.GROSS_BETA_FLAG == null">{{
              item.GROSS_BETA
            }}</span>
            <br v-if="item.GROSS_BETA_FLAG == null" />
            <font color="red"
              ><b>{{ item.GROSS_BETA_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.HARDNESS`]="{ item }">
            <span v-if="item.HARDNESS_FLAG == null">{{ item.HARDNESS }}</span>
            <br v-if="item.HARDNESS_FLAG == null" />
            <font color="red"
              ><b>{{ item.HARDNESS_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.HEDONAL_2_4_D`]="{ item }">
            <span v-if="item.HEDONAL_2_4_D_FLAG == null">{{
              item.HEDONAL_2_4_D
            }}</span>
            <br v-if="item.HEDONAL_2_4_D_FLAG == null" />
            <font color="red"
              ><b>{{ item.HEDONAL_2_4_D_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.HEPTACHLOR_EPOXIDE`]="{ item }">
            <span v-if="item.HEPTACHLOR_EPOXIDE_FLAG == null">{{
              item.HEPTACHLOR_EPOXIDE
            }}</span>
            <br v-if="item.HEPTACHLOR_EPOXIDE_FLAG == null" />
            <font color="red"
              ><b>{{ item.HEPTACHLOR_EPOXIDE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.HG`]="{ item }">
            <span v-if="item.HG_FLAG == null">{{ item.HG }}</span>
            <br v-if="item.HG_FLAG == null" />
            <font color="red"
              ><b>{{ item.HG_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.K`]="{ item }">
            <span v-if="item.K_FLAG == null">{{ item.K }}</span>
            <br v-if="item.K_FLAG == null" />
            <font color="red"
              ><b>{{ item.K_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.LINDANE`]="{ item }">
            <span v-if="item.LINDANE_FLAG == null">{{ item.LINDANE }}</span>
            <br v-if="item.LINDANE_FLAG == null" />
            <font color="red"
              ><b>{{ item.LINDANE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.MBAS`]="{ item }">
            <span v-if="item.MBAS_FLAG == null">{{ item.MBAS }}</span>
            <br v-if="item.MBAS_FLAG == null" />
            <font color="red"
              ><b>{{ item.MBAS_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.MG`]="{ item }">
            <span v-if="item.MG_FLAG == null">{{ item.MG }}</span>
            <br v-if="item.MG_FLAG == null" />
            <font color="red"
              ><b>{{ item.MG_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.MN`]="{ item }">
            <span v-if="item.MN_FLAG == null">{{ item.MN }}</span>
            <br v-if="item.MN_FLAG == null" />
            <font color="red"
              ><b>{{ item.MN_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.NA`]="{ item }">
            <span v-if="item.NA_FLAG == null">{{ item.NA }}</span>
            <br v-if="item.NA_FLAG == null" />
            <font color="red"
              ><b>{{ item.NA_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.NH4`]="{ item }">
            <span v-if="item.NH4_FLAG == null">{{ item.NH4 }}</span>
            <br v-if="item.NH4_FLAG == null" />
            <font color="red"
              ><b>{{ item.NH4_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.NI`]="{ item }">
            <span v-if="item.NI_FLAG == null">{{ item.NI }}</span>
            <br v-if="item.NI_FLAG == null" />
            <font color="red"
              ><b>{{ item.NI_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.NO2`]="{ item }">
            <span v-if="item.NO2_FLAG == null">{{ item.NO2 }}</span>
            <br v-if="item.NO2_FLAG == null" />
            <font color="red"
              ><b>{{ item.NO2_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.NO3`]="{ item }">
            <span v-if="item.NO3_FLAG == null">{{ item.NO3 }}</span>
            <br v-if="item.NO3_FLAG == null" />
            <font color="red"
              ><b>{{ item.NO3_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.OG_EDIBLE`]="{ item }">
            <span v-if="item.OG_EDIBLE_FLAG == null">{{ item.OG_EDIBLE }}</span>
            <br v-if="item.OG_EDIBLE_FLAG == null" />
            <font color="red"
              ><b>{{ item.OG_EDIBLE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.OG_MINERAL`]="{ item }">
            <span v-if="item.OG_MINERAL_FLAG == null">{{
              item.OG_MINERAL
            }}</span>
            <br v-if="item.OG_MINERAL_FLAG == null" />
            <font color="red"
              ><b>{{ item.OG_MINERAL_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.P`]="{ item }">
            <span v-if="item.P_FLAG == null">{{ item.P }}</span>
            <br v-if="item.P_FLAG == null" />
            <font color="red"
              ><b>{{ item.P_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.PARAQUAT`]="{ item }">
            <span v-if="item.PARAQUAT_FLAG == null">{{ item.PARAQUAT }}</span>
            <br v-if="item.PARAQUAT_FLAG == null" />
            <font color="red"
              ><b>{{ item.PARAQUAT_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.PB`]="{ item }">
            <span v-if="item.PB_FLAG == null">{{ item.PB }}</span>
            <br v-if="item.PB_FLAG == null" />
            <font color="red"
              ><b>{{ item.PB_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.PCB`]="{ item }">
            <span v-if="item.PCB_FLAG == null">{{ item.PCB }}</span>
            <br v-if="item.PCB_FLAG == null" />
            <font color="red"
              ><b>{{ item.PCB_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.PHENOL`]="{ item }">
            <span v-if="item.PHENOL_FLAG == null">{{ item.PHENOL }}</span>
            <br v-if="item.PHENOL_FLAG == null" />
            <font color="red"
              ><b>{{ item.PHENOL_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.PO4_3`]="{ item }">
            <span v-if="item.PO4_3_FLAG == null">{{ item.PO4_3 }}</span>
            <br v-if="item.PO4_3_FLAG == null" />
            <font color="red"
              ><b>{{ item.PO4_3_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.RA226`]="{ item }">
            <span v-if="item.RA226_FLAG == null">{{ item.RA226 }}</span>
            <br v-if="item.RA226_FLAG == null" />
            <font color="red"
              ><b>{{ item.RA226_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.S`]="{ item }">
            <span v-if="item.S_FLAG == null">{{ item.S }}</span>
            <br v-if="item.S_FLAG == null" />
            <font color="red"
              ><b>{{ item.S_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SALINITY`]="{ item }">
            <span v-if="item.SALINITY_FLAG == null">{{ item.SALINITY }}</span>
            <br v-if="item.SALINITY_FLAG == null" />
            <font color="red"
              ><b>{{ item.SALINITY_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SE`]="{ item }">
            <span v-if="item.SE_FLAG == null">{{ item.SE }}</span>
            <br v-if="item.SE_FLAG == null" />
            <font color="red"
              ><b>{{ item.SE_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SILICA`]="{ item }">
            <span v-if="item.SILICA_FLAG == null">{{ item.SILICA }}</span>
            <br v-if="item.SILICA_FLAG == null" />
            <font color="red"
              ><b>{{ item.SILICA_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SILVEX_2_4_5_TP`]="{ item }">
            <span v-if="item.SILVEX_2_4_5_TP_FLAG == null">{{
              item.SILVEX_2_4_5_TP
            }}</span>
            <br v-if="item.SILVEX_2_4_5_TP_FLAG == null" />
            <font color="red"
              ><b>{{ item.SILVEX_2_4_5_TP_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SN`]="{ item }">
            <span v-if="item.SN_FLAG == null">{{ item.SN }}</span>
            <br v-if="item.SN_FLAG == null" />
            <font color="red"
              ><b>{{ item.SN_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SO4`]="{ item }">
            <span v-if="item.SO4_FLAG == null">{{ item.SO4 }}</span>
            <br v-if="item.SO4_FLAG == null" />
            <font color="red"
              ><b>{{ item.SO4_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SOLAR_BATTERY`]="{ item }">
            <span v-if="item.SOLAR_BATTERY_FLAG == null">{{
              item.SOLAR_BATTERY
            }}</span>
            <br v-if="item.SOLAR_BATTERY_FLAG == null" />
            <font color="red"
              ><b>{{ item.SOLAR_BATTERY_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.SR_90`]="{ item }">
            <span v-if="item.SR_90_FLAG == null">{{ item.SR_90 }}</span>
            <br v-if="item.SR_90_FLAG == null" />
            <font color="red"
              ><b>{{ item.SR_90_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.TC`]="{ item }">
            <span v-if="item.TC_FLAG == null">{{ item.TC }}</span>
            <br v-if="item.TC_FLAG == null" />
            <font color="red"
              ><b>{{ item.TC_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.TDS`]="{ item }">
            <span v-if="item.TDS_FLAG == null">{{ item.TDS }}</span>
            <br v-if="item.TDS_FLAG == null" />
            <font color="red"
              ><b>{{ item.TDS_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.TEMPERATURE`]="{ item }">
            <span v-if="item.TEMP_FLAG == null">{{ item.TEMPERATURE }}</span>
            <br v-if="item.TEMP_FLAG == null" />
            <font color="red"
              ><b>{{ item.TEMP_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.TOC`]="{ item }">
            <span v-if="item.TOC_FLAG == null">{{ item.TOC }}</span>
            <br v-if="item.TOC_FLAG == null" />
            <font color="red"
              ><b>{{ item.TOC_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.TRIOXONE_2_4_5_T`]="{ item }">
            <span v-if="item.TRIOXONE_2_4_5_T_FLAG == null">{{
              item.TRIOXONE_2_4_5_T
            }}</span>
            <br v-if="item.TRIOXONE_2_4_5_T_FLAG == null" />
            <font color="red"
              ><b>{{ item.TRIOXONE_2_4_5_T_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.TURBIDITY`]="{ item }">
            <span v-if="item.TURBIDITY_FLAG == null">{{ item.TURBIDITY }}</span>
            <br v-if="item.TURBIDITY_FLAG == null" />
            <font color="red"
              ><b>{{ item.TURBIDITY_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.U`]="{ item }">
            <span v-if="item.U_FLAG == null">{{ item.U }}</span>
            <br v-if="item.U_FLAG == null" />
            <font color="red"
              ><b>{{ item.U_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.VELOCITY`]="{ item }">
            <span v-if="item.VELOCITY_FLAG == null">{{ item.VELOCITY }}</span>
            <br v-if="item.VELOCITY_FLAG == null" />
            <font color="red"
              ><b>{{ item.VELOCITY_FLAG }}</b></font
            >
          </template>

          <template v-slot:[`item.ZN`]="{ item }">
            <span v-if="item.ZN_FLAG == null">{{ item.ZN }}</span>
            <br v-if="item.ZN_FLAG == null" />
            <font color="red"
              ><b>{{ item.ZN_FLAG }}</b></font
            >
          </template>

          <!-- TAMBAHAN PARAM! -->
          <template v-slot:[`item.TRYPHPTOPHAN`]="{ item }">
            <span v-if="item.TRYPHPTOPHAN_FLAG == null">{{
              item.TRYPHPTOPHAN
            }}</span>
            <br v-if="item.TRYPHPTOPHAN_FLAG == null" />
            <font color="red"
              ><b>{{ item.TRYPHPTOPHAN_FLAG }}</b></font
            >
          </template>
        </v-data-table>

        <!-- Note -->
        <!-- <div class="parent-btn-generate-chart">
                <v-subheader
                class="subTitle black--text"
                >
                Note: Red text indicated that value has exceeded above maximum & minimum value
                </v-subheader>
              </div> -->
      </div>

      <!-- Button Back to Front Page from Table -->
      <div class="parent-btn-generate-chart">
        <v-btn
          class="ma-2 white--text primary"
          solo
          dense
          normal
          @click="backFrontPageFromTable"
        >
          Back
          <v-icon right dark big>
            mdi-arrow-left-top-bold
          </v-icon>
        </v-btn>
      </div>
    </v-container>

    <!-- Section Result Generated Report (Show Chart) -->
    <v-container class="container-result-chart">
      <!-- Chart -->
      <highcharts
        :constructorType="'stockChart'"
        class="hc"
        :options="chartOptions"
        ref="chart"
      >
      </highcharts>

      <!-- Button Back to Front Page from Chart -->
      <div class="parent-btn-generate-chart">
        <v-btn
          class="ma-2 white--text primary"
          solo
          dense
          normal
          @click="backFrontPageFromChart"
        >
          Back
          <v-icon right dark big>
            mdi-arrow-left-top-bold
          </v-icon>
        </v-btn>
      </div>
    </v-container>

    <template>
      <v-footer padless>
        <v-col class="text-center footer-report" cols="12">
          <!-- {{ new Date().getFullYear() }} — <strong>Vuetify</strong> -->
          <!-- <span class="one-text-footer mx-5">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="black--text">NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span> -->

          <span class="one-text-footer mx-5">
            <span class="red--text">C</span>
            <span class="blue--text">=CALIBRATION</span>
          </span>
          <span class="one-text-footer mx-5">
            <span class="red--text">M</span>
            <span class="blue--text">=MAINTENANCE</span>
          </span>
          <span class="one-text-footer mx-5 dontBreak">
            <span class="red--text">DOR</span>
            <span class="blue--text">=DATA OUT OF SENSOR RANGE</span> </span
          ><br />
          <span class="one-text-footer mx-5">
            <span class="red--text">OUTL</span>
            <span class="blue--text">=OUTLIER</span>
          </span>
          <span class="one-text-footer mx-5 dontBreak">
            <span class="red--text">PRBC</span>
            <span class="blue--text">=PARAMETER RELATIONSHIP(BOD≥COD)</span>
          </span>
          <span class="one-text-footer mx-5">
            <span>NA</span>
            <span class="blue--text">=DATA NOT AVAILABLE</span>
          </span>
        </v-col>
      </v-footer>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";

export default {
  data: () => ({
    globalAllParameter: [],

    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/",
        // href: '/Mapviewer',
      },
      {
        text: "Report - Query Options",
        disabled: true,
        href: "/Report/QueryOptions",
      },
    ],
    dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    // dateFormatted: pathModul.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    menu: false,
    modalCalendarFrom: false,
    modalCalendarTo: false,
    menu2: false,
    // e6: [],
    // e7: [],
    station: [],
    allParamsStationSelected: [],
    parameter: [],
    disabledInputParam: true,
    loadingInputParam: false,
    disableGenerateButton: true,

    stationSelected: [],
    parameterSelected: [],

    displayStationSelected: [],
    displayParameterSelected: null,

    // DataTable
    loadingTable: false,
    loadingText: "",

    sendStation: [],
    displayStation: [],
    sendParameter: [],
    headerStation: {
      text: "Station ID",
      sortable: true,
      value: "STATION_ID",
      divider: true,
      align: "center",
    },
    headerLocation: {
      text: "Location",
      sortable: true,
      value: "location",
      divider: true,
      align: "center",
    },
    headerDate: {
      text: "Datetime",
      sortable: true,
      value: "datetime_a",
      divider: true,
      align: "center",
    },
    dtheader: [],
    dtbody: [],

    chartOptions: {
      chart: {
        height: 600,
        // type: 'spline'
      },
      title: {
        text: "Water Quality Status",
      },
      subtitle: {
        text: "",
      },
      yAxis: {
        plotLines: [],
      },
      series: null,
      legend: {
        enabled: true,
      },
    },

    plotLinesValue: [
      { param: "WQI", class1: 92.7, class2: 76.5, class3: 51.9, class4: 31.0, class5: 30.9, threshold: null},
      { param: "DO_CON", class1: 7, class2: 5, class3: 3, class4: 1, class5: 1, threshold: null},
      { param: "BOD", class1: 0.98, class2: 1, class3: 4, class4: 6, class5: 12, threshold: null},
      { param: "COD", class1: 9.98, class2: 10, class3: 25, class4: 50, class5: 100, threshold: null},
      { param: "NH3N", class1: 0.098, class2: 0.1, class3: 0.3, class4: 0.9, class5: 2.7, threshold: null},
      { param: "TSS", class1: 24.98, class2: 25, class3: 50, class4: 150, class5: 300, threshold: null},
      { param: "pH", class1: 7, class2: 6, class3: 5, class4: 4.98, threshold: null},
      // {
      //   param: "WQI",
      //   class1: 100,
      //   class2: 92.6,
      //   class3: 76.4,
      //   class4: 51.8,
      //   class5: 30.9,
      //   threshold: null,
      // },
      // {
      //   param: "DO_CON",
      //   class1: 7,
      //   class2: 5,
      //   class3: 3,
      //   class4: 1,
      //   class5: -7,
      //   threshold: null,
      // },
      // {
      //   param: "BOD",
      //   class1: 0,
      //   class2: 2,
      //   class3: 4,
      //   class4: 7,
      //   class5: 13,
      //   threshold: null,
      // },
      // {
      //   param: "COD",
      //   class1: 0,
      //   class2: 11,
      //   class3: 26,
      //   class4: 51,
      //   class5: 101,
      //   threshold: null,
      // },
      // {
      //   param: "NH3N",
      //   class1: 0,
      //   class2: 0.2,
      //   class3: 0.4,
      //   class4: 1,
      //   class5: 2.8,
      //   threshold: null,
      // },
      // {
      //   param: "TSS",
      //   class1: 0,
      //   class2: 26,
      //   class3: 51,
      //   class4: 151,
      //   class5: 301,
      //   threshold: null,
      // },
      // {
      //   param: "pH",
      //   class1: 7,
      //   class2: 6,
      //   class3: 5,
      //   class4: 0,
      //   class5: 0,
      //   threshold: null,
      // },
    ],
  }),
  methods: {
    // convert12Hours(time) {
    //     var H = +time.substr(0, 2);
    //     var h = H % 12 || 12;
    //     var ampm = (H < 12 || H === 24) ? "AM" : "PM";
    //     time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
    //     return time;
    // },

    // convertDateFormat(date){
    //     var dd = date.split("-")[2];
    //     var mm = date.split("-")[1];
    //     var yyyy = date.split("-")[0];
    //     date = dd + "/" + mm + "/" + yyyy;
    //     return date;
    // },

    load() {
      this.getApiLisTStation();
      this.getApiListParameter();
    },

    getApiLisTStation() {
      axios
        .get("https://temptunnel1.shaz.my/api/cms/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
            this.station.push(
              response.data[i].stationId + " || " + response.data[i].location
            );
          }

          // console.log(this.station);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getApiListParameter() {
      axios
        .get("https://temptunnel1.shaz.my/api/cms/params", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
            // if (response.data[i].reading != "WQI" && response.data[i].reading != "SI_DO_SAT"
            // && response.data[i].reading != "DO_CON" && response.data[i].reading != "DO_SAT"
            // && response.data[i].reading != "SI_BOD" && response.data[i].reading != "BOD"
            // && response.data[i].reading != "SI_COD" && response.data[i].reading != "COD"
            // && response.data[i].reading != "SI_NH3N" && response.data[i].reading != "NH3N"
            // && response.data[i].reading != "SI_TSS" && response.data[i].reading != "TSS"
            // && response.data[i].reading != "SI_pH" && response.data[i].reading != "pH") {
            //     this.parameter.push(response.data[i].text + ' || ' + response.data[i].value);
            //     this.globalAllParameter.push(response.data[i]);
            // }

            // this.parameter.push(response.data[i].text + ' || ' + response.data[i].value);
            // this.globalAllParameter.push(response.data[i]);

            // this.allParams.push(response.data[i].text + ' || ' + response.data[i].value);
            this.globalAllParameter.push(response.data[i]);
            // console.log(this.globalAllParameter);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Get value from Selected Station
    selectStation: function(e) {
      // console.log(e);

      console.log(this.stationSelected);

      // this.stationSelected = e;
      // console.log(this.stationSelected);
      this.loadingInputParam = true;

      if (this.stationSelected.length > 0) {
        axios
          .get("https://temptunnel1.shaz.my/api/cms/stations", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            // console.log(response.data);
            let data = response.data;

            let dataStationSelected = [];
            let tempLast = [];
            this.allParamsStationSelected = [];
            this.parameter = [];

            for (let i = 0; i < data.length; i++) {
              for (let x = 0; x < this.stationSelected.length; x++) {
                if (
                  data[i].stationId == this.stationSelected[x].split(" || ")[0]
                ) {
                  dataStationSelected.push(data[i]);
                }
              }
            }

            // console.log(dataStationSelected);

            let keyData = Object.keys(dataStationSelected[0]);
            for (let i = 0; i < dataStationSelected.length; i++) {
              for (let x = 0; x < keyData.length; x++) {
                for (let z = 0; z < this.globalAllParameter.length; z++) {
                  if (
                    dataStationSelected[i][keyData[x]] ==
                      this.globalAllParameter[z].reading ||
                    "_" + dataStationSelected[i][keyData[x]] ==
                      this.globalAllParameter[z].reading
                  ) {
                    tempLast.push(this.globalAllParameter[z]);
                  } else if (
                    this.globalAllParameter[z].reading == "WQI" ||
                    this.globalAllParameter[z].reading == "SI_DO_SAT" ||
                    this.globalAllParameter[z].reading == "SI_BOD" ||
                    this.globalAllParameter[z].reading == "SI_COD" ||
                    this.globalAllParameter[z].reading == "SI_NH3N" ||
                    this.globalAllParameter[z].reading == "SI_TSS" ||
                    this.globalAllParameter[z].reading == "SI_pH"
                  ) {
                    tempLast.unshift(this.globalAllParameter[z]);
                  }
                }
              }
            }
            // console.log(tempLast);

            this.allParamsStationSelected = tempLast.filter(
              (v, i, a) =>
                a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) ===
                i
            );
            // console.log(this.allParamsStationSelected);

            // Keluarkan list layer di input html
            for (let i = 0; i < this.allParamsStationSelected.length; i++) {
              this.parameter.push(
                this.allParamsStationSelected[i].text +
                  " || " +
                  this.allParamsStationSelected[i].value
              );
            }

            this.disabledInputParam = false;
            this.loadingInputParam = false;
            this.disableGenerateButton = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.stationSelected.length == 0) {
        this.parameter = [];
        this.disabledInputParam = true;
        this.loadingInputParam = false;
        this.disableGenerateButton = true;
      }
    },

    // Load Data based on Station, Date & Parameter selected
    loadDataQuery(station, param, typeQuery) {
      // let textStationList = "";
      // console.log(textStationList);
      // for (let i = 0; i < station.length; i++) {
      //     textStationList = "(" + station[i++] + ")";
      // }

      // console.log(textStationList);

      // displayParameterSelected

      // console.log(station);
      console.log(param);
      axios
        .get(
          "https://temptunnel1.shaz.my/api/cms/rawdata?startdt=" +
            this.dateFrom +
            "&enddt=" +
            this.dateTo +
            "&stationid=all&raw=0",
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);

          // for (let i = 0; i < response.data.length; i++) {
          //     let keyData = Object.keys(response.data[0])

          //     for (let x = 0; x < keyData.length; x++) {
          //         if (response.data[i][keyData[x]] < 0) {
          //             response.data[i][keyData[x]] = null;
          //         }
          //     }
          // }

          if (typeQuery == "queryTable") {
            // Create/Generate Header Table
            for (let i = 0; i < this.globalAllParameter.length; i++) {
              for (let x = 0; x < param.length; x++) {
                if (this.globalAllParameter[i].value === param[x]) {
                  this.dtheader.push(this.globalAllParameter[i]);
                }
              }
            }
            this.dtheader.unshift(this.headerDate);
            this.dtheader.unshift(this.headerLocation);
            this.dtheader.unshift(this.headerStation);

            this.loadingTable = false;
            this.loadingText = "";

            // Create/Generate Body Table
            let dataStationSelected = [];
            // Filter Data Based On Station Selected First
            for (let i = 0; i < response.data.length; i++) {
              let keyData = Object.keys(response.data[0]);

              // if (keyData[x].includes("FLAG")) {
              //     // console.log(keyData[x]);
              //     if (response.data[i][keyData[x]] < 0 || response.data[i][keyData[x]] == "NULL" || response.data[i][keyData[x]] == "null") {
              //     response.data[i][keyData[x]] = null;
              //     // console.log(response.data[i]);
              //     this.GlobalDataDaily.push(response.data);
              //     }
              //     this.GlobalDataDaily.push(response.data);
              // }

              // response.data[i]["datetime_a"] = response.data[i]["datetime_a"].replaceAll('T', '<br>');

              // console.log(tempTime);

              // let intt = setInterval(() => {

              //     response.data[i]["datetime_a"] = this.convertDateFormat(response.data[i]["datetime_a"].split("T")[0])
              //     + "<br>"
              //     + this.convert12Hours(response.data[i]["datetime_a"].split("T")[1]);

              //     // console.log(this.convert12Hours(response.data[i]["datetime_a"].split("T")[1]));

              // clearInterval(intt);
              // }, 1000);

              // for (let x = 0; x < keyData.length; x++) {
              //     if (response.data[i][keyData[x]] == null) {
              //         response.data[i][keyData[x]] = "NA";
              //     }
              // }
              for (let x = 0; x < keyData.length; x++) {
                if (keyData[x].includes("FLAG") == false) {
                  // if (response.data[i].WQI == null) {
                  //     response.data[i].WQI = "NA";
                  // }
                  if (
                    response.data[i][keyData[x]] == null ||
                    response.data[i][keyData[x]] == "NULL" ||
                    response.data[i][keyData[x]] == "null"
                  ) {
                    response.data[i][keyData[x]] = "NA";
                  } else {
                    if (
                      keyData[x] != "SI_DO_SAT" &&
                      keyData[x] != "SI_BOD" &&
                      keyData[x] != "SI_COD" &&
                      keyData[x] != "SI_NH3N" &&
                      keyData[x] != "SI_TSS" &&
                      keyData[x] != "SI_pH"
                    ) {
                      if (typeof response.data[i][keyData[x]] == "number") {
                        response.data[i][keyData[x]] = response.data[i][
                          keyData[x]
                        ].toFixed(4);
                      }
                    }
                  }
                  if (
                    response.data[i].WQI < 0 ||
                    response.data[i].WQI == null ||
                    response.data[i].WQI == "NULL" ||
                    response.data[i].WQI == "null"
                  ) {
                    response.data[i].WQI = "NA";
                  }
                }
              }
              for (let x = 0; x < station.length; x++) {
                if (response.data[i].STATION_ID === station[x]) {
                  // console.log(response.data[i].STATION_ID);
                  dataStationSelected.push(response.data[i]);

                  this.dtbody.push(response.data[i]);
                }
              }

              let tempTime =
                this.convertDateFormat(
                  response.data[i]["datetime_a"].split("T")[0]
                ) +
                "<br>" +
                this.convert12Hours(
                  response.data[i]["datetime_a"].split("T")[1]
                );

              response.data[i]["datetime_a"] = tempTime;
            }

            console.log(this.dtbody);

            document.querySelector(".subTitle").style.display = "block";

            // console.log(dataStationSelected);

            // console.log(document.getElementById("btnExportExcel"));

            if (this.dtbody.length > 0) {
              document.querySelector(".btnExportExcelQQ").style.display =
                "block";
            }
          } else if (typeQuery == "queryChart") {
            // console.log(response.data);

            for (let i = 0; i < response.data.length; i++) {
              let keyData = Object.keys(response.data[0]);
              // response.data[i]["fulldatetime"] = response.data[i].datetime_a.split('T')[0] + '<br>' + response.data[i].datetime_a.split('T')[1];
              // response.data[i]["datetime_a"] = response.data[i]["datetime_a"].replaceAll('T', '<br>');
              for (let x = 0; x < keyData.length; x++) {
                if (response.data[i][keyData[x]] == "NA") {
                  response.data[i][keyData[x]] = null;
                }
              }
            }

            // Create/Generate Data Chart
            let dataRenderingChartStation = [];
            // let dataRenderingChartParam = [];
            // Filter Data Based On Station Selected First
            for (let i = 0; i < response.data.length; i++) {
              for (let x = 0; x < station.length; x++) {
                if (response.data[i].STATION_ID === station[x]) {
                  dataRenderingChartStation.push(response.data[i]);
                }
              }
            }
            // console.log('https://temptunnel1.shaz.my/api/cms/rawdata?startdt='+ this.dateFrom +'&enddt='+ this.dateTo +'&stationid=all&raw=0');
            console.log(dataRenderingChartStation);

            // -1 set to null
            for (let i in dataRenderingChartStation) {
              for (let x in param) {
                if (dataRenderingChartStation[i][param[x]] < 0) {
                  dataRenderingChartStation[i][param[x]] = null;
                }
              }
            }

            // Create data series dan display highchart
            let obj = [];
            for (let i = 0; i < param.length; i++) {
              for (let x = 0; x < station.length; x++) {
                obj.push({
                  showInLegend: true,
                  name: station[x] + " - " + param[i],
                  data: [],
                  threshold: null,
                });
                // console.log(obj);
                // dataRenderingChartStation.forEach(function(res, index) {
                //   // console.log(res);
                //   for (let z = 0; z < obj.length; z++) {
                //     console.log(obj.length);
                //     if (obj[z].name.split(" - ")[0] == res.STATION_ID) {
                //       // obj[z].data.push([res.epochdate,res[param[i]]]);

                //       let myInterval = setInterval(() => {
                //         obj[z].data.push([res.epochdate, res[param[i]]]);
                //         clearInterval(myInterval);
                //       }, 0);
                //     }
                //   }
                // });

                


              }

              

              // console.log(obj);
            }

            // for(let a in dataRenderingChartStation){
                  for (let z in obj) {
                    for(let a in dataRenderingChartStation){
                      console.log(obj.length);
                      if (obj[z].name.split(" - ")[0] == dataRenderingChartStation[a].STATION_ID) {
                        // obj[z].data.push([res.epochdate,res[param[i]]]);

                        let myInterval = setInterval(() => {
                          obj[z].data.push([dataRenderingChartStation[a].epochdate, dataRenderingChartStation[a][obj[z].name.split(" - ")[1]]]);
                          clearInterval(myInterval);
                        }, 0);
                      }
                    }
                  }
              // }

            console.log(obj);

            // console.log(obj[obj.length - 1]);

            // let totalZero = null;
            let totalZero = obj.filter((el) => el.data.length === 0).length;
            // console.log(totalZero);
            for (let i = 0; i < obj.length; i++) {
              if (obj[i].data.length != 0) {
                // console.log(totalAvailable);
                if (totalZero > 0) {
                  let TotalRealObj =
                    dataRenderingChartStation.length / (obj.length - totalZero);
                  // console.log(Math.round(TotalRealObj, 0));
                  obj[i].data.length = Math.round(TotalRealObj, 0);
                } else if (totalZero == 0) {
                  let TotalRealObj =
                    dataRenderingChartStation.length / obj.length;
                  obj[i].data.length = Math.round(TotalRealObj, 0);
                }
              }
            }

            // console.log(obj);

            // this.chartOptions.series = obj

            let myInterval = setInterval(() => {
              this.chartOptions.series = obj;
              clearInterval(myInterval);
            }, 1000);

            // Subtitle Chart
            // this.chartOptions.subtitle.text = "Selected Parameters for "+ this.GlobalDataDaily[0][0]["LOCATION"] +" ("+ this.valStation.split(' - ')[0] +")"

            // Keluarkan Class If Param WQI selected 1
            let thresholdValue = null;
            this.chartOptions.yAxis.plotLines = [];
            // console.log(param.length);
            if (param.length == 1) {
              let objPlotClass = [];

              for (let i = 0; i < this.plotLinesValue.length; i++) {
                if (this.plotLinesValue[i].param == param[0]) {

                  if(this.plotLinesValue[i].param == "WQI"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top'},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top',y: 15},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              if(this.plotLinesValue[i].param == "DO_CON"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top'},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top',y: 15},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "BOD" || this.plotLinesValue[i].param == "COD"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top',y: 15},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top'},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "NH3N"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'bottom', y: 15},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V',verticalAlign: 'top'},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "TSS"){
                objPlotClass.push(
                  {
                    label: {text:'Class I', y: 15},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV'},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class V'},
                    color: 'red',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class5,
                    width: 2,
                    zIndex:4,
                  },
                )
              }
              else if(this.plotLinesValue[i].param == "pH"){
                objPlotClass.push(
                  {
                    label: {text:'Class I',verticalAlign: 'top'},
                    color: 'blue',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class1,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class II',verticalAlign: 'top'},
                    color: 'green',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class2,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class III',verticalAlign: 'top'},
                    color: 'yellow',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class3,
                    width: 2,
                    zIndex:4,
                  },
                  {
                    label: {text:'Class IV',verticalAlign: 'top', y: 15},
                    color: 'orange',
                    dashStyle: 'ShortDash',
                    value: this.plotLinesValue[i].class4,
                    width: 2,
                    zIndex:4,
                  },
                )
              }

                  // objPlotClass.push(
                  //   {
                  //     label: { text: "Class I", verticalAlign: "top" },
                  //     color: "blue",
                  //     dashStyle: "ShortDash",
                  //     value: this.plotLinesValue[i].class1,
                  //     width: 2,
                  //     zIndex: 4,
                  //   },
                  //   {
                  //     label: { text: "Class II", verticalAlign: "top" },
                  //     color: "green",
                  //     dashStyle: "ShortDash",
                  //     value: this.plotLinesValue[i].class2,
                  //     width: 2,
                  //     zIndex: 4,
                  //   },
                  //   {
                  //     label: { text: "Class III", verticalAlign: "top" },
                  //     color: "yellow",
                  //     dashStyle: "ShortDash",
                  //     value: this.plotLinesValue[i].class3,
                  //     width: 2,
                  //     zIndex: 4,
                  //   },
                  //   {
                  //     label: { text: "Class IV", verticalAlign: "top" },
                  //     color: "orange",
                  //     dashStyle: "ShortDash",
                  //     value: this.plotLinesValue[i].class4,
                  //     width: 2,
                  //     zIndex: 4,
                  //   },
                  //   {
                  //     label: { text: "Class V", verticalAlign: "top" },
                  //     color: "red",
                  //     dashStyle: "ShortDash",
                  //     value: this.plotLinesValue[i].class5,
                  //     width: 2,
                  //     zIndex: 4,
                  //   }
                  // );

                  this.chartOptions.yAxis.plotLines = objPlotClass;

                  let arrayValue = [];
                  for (let k = 0; k < dataRenderingChartStation.length; k++) {
                    let keyData = Object.keys(dataRenderingChartStation[0]);
                    for (let f = 0; f < keyData.length; f++) {
                      if (keyData[f] == param[0]) {
                        arrayValue.push(
                          dataRenderingChartStation[k][keyData[f]]
                        );
                      }
                    }
                  }

                  let maximum = Math.max(...arrayValue);
                  // console.log(maximum);

                  if (maximum > this.plotLinesValue[i].class1) {
                    thresholdValue = this.plotLinesValue[i].class1;
                  } else if (maximum > this.plotLinesValue[i].class2) {
                    thresholdValue = this.plotLinesValue[i].class2;
                  } else if (maximum > this.plotLinesValue[i].class3) {
                    thresholdValue = this.plotLinesValue[i].class3;
                  } else if (maximum > this.plotLinesValue[i].class4) {
                    thresholdValue = this.plotLinesValue[i].class4;
                  } else if (maximum < this.plotLinesValue[i].class5) {
                    thresholdValue = this.plotLinesValue[i].class5;
                  } else {
                    thresholdValue = null;
                  }
                }
              }

              // console.log(obj);

              for (let index = 0; index < obj.length; index++) {
                obj[index].threshold = thresholdValue;
              }
            } else {
              this.chartOptions.yAxis.plotLines = [];

              for (let index = 0; index < obj.length; index++) {
                obj[index].threshold = null;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Click Button Generate Table (Standard Query)
    generateTable() {
      this.loadingTable = true;
      this.loadingText = "Data Loading... Please wait";

      this.sendStation = [];
      this.displayStation = [];
      this.sendParameter = [];
      this.dtheader = [];
      this.dtbody = [];

      if (this.stationSelected.length > 0) {
        for (let i = 0; i < this.stationSelected.length; i++) {
          this.sendStation.push(this.stationSelected[i].split(" || ")[0]);
          this.displayStation.push(this.stationSelected[i].split(" || ")[0]);
        }
      }

      if (this.parameterSelected.length > 0) {
        for (let i = 0; i < this.parameterSelected.length; i++) {
          this.sendParameter.push(this.parameterSelected[i].split(" || ")[1]);
        }
      }

      // console.log(this.sendStation);
      // console.log(this.sendParameter);

      this.loadDataQuery(this.sendStation, this.sendParameter, "queryTable");

      document.querySelector(".container-filter").style.display = "none";
      document.querySelector(".container-result-table").style.display = "block";
    },

    // Customize background color for column table for result WQI status
    getColor(wqi) {
      if (wqi > 92.7) {
        return "#3486FA";
      } else if (wqi > 76.5 && wqi <= 92.7) {
        return "#7AE666";
      } else if (wqi >= 50 && wqi <= 76.5) {
        return "#FFFF10";
      } else if (wqi >= 31.0 && wqi <= 51.9) {
        return "#FFAB3D";
      } else if (wqi >= 1 && wqi <= 30.9) {
        return "#FB401D";
      } else {
        return "white";
      }
    },

    // Customize font color for column table for result WQI status
    getFontColor(wqi) {
      if (wqi > 92.7) {
        return "white--text";
      } else if (wqi > 76.5 && wqi <= 92.7) {
        return "black--text";
      } else if (wqi >= 50 && wqi <= 76.5) {
        return "black--text";
      } else if (wqi >= 31.0 && wqi <= 51.9) {
        return "white--text";
      } else if (wqi >= 1 && wqi <= 30.9) {
        return "white--text";
      } else {
        return "red--text";
      }
    },

    // Back to front page from form table
    backFrontPageFromTable() {
      document.querySelector(".container-filter").style.display = "block";
      document.querySelector(".container-result-table").style.display = "none";

      document.querySelector(".btnExportExcelQQ").style.display = "none";
    },

    // Click Button Generate Chart (Parameter Graph)
    generateChart() {
      this.sendStation = [];
      this.sendParameter = [];
      this.chartOptions.series = [];

      if (this.stationSelected.length > 0) {
        for (let i = 0; i < this.stationSelected.length; i++) {
          this.sendStation.push(this.stationSelected[i].split(" || ")[0]);
        }
      }

      if (this.parameterSelected.length > 0) {
        for (let i = 0; i < this.parameterSelected.length; i++) {
          this.sendParameter.push(this.parameterSelected[i].split(" || ")[1]);
        }
      }

      this.loadDataQuery(this.sendStation, this.sendParameter, "queryChart");

      document.querySelector(".container-filter").style.display = "none";
      document.querySelector(".container-result-chart").style.display = "block";
    },

    backFrontPageFromChart() {
      document.querySelector(".container-filter").style.display = "block";
      document.querySelector(".container-result-chart").style.display = "none";
    },

    // Export Table
    exportReport() {
      // console.log(this.dtheader);
      // console.log(this.dtbody);

      let keyData = Object.keys(this.dtbody[0]);
      let keyParamsFlag = [];
      let keyParamsNonFlag = [];
      // console.log(keyData);
      for (let x in keyData) {
        if (keyData[x].includes("FLAG")) {
          // console.log(keyData[x]);
          keyParamsFlag.push(keyData[x]);
        } else {
          keyParamsNonFlag.push(keyData[x]);
        }
      }

      let keysFlag = keyParamsFlag,
        resultFlag = this.dtbody.map((o) =>
          Object.assign(...keysFlag.map((k) => ({ [k]: o[k] })))
        );
      console.log(resultFlag);

      let valueHeader = [];
      for (let i = 0; i < this.dtheader.length; i++) {
        valueHeader.push(this.dtheader[i].value);
      }

      console.log(valueHeader);
      console.log(this.dtbody);

      let keysWithoutFlag = valueHeader,
        result = this.dtbody.map((o) =>
          Object.assign(...keysWithoutFlag.map((k) => ({ [k]: o[k] })))
        );

      // console.log(result);

      for (let i in resultFlag) {
        for (let j in result) {
          if (i == j) {
            for (let x in valueHeader) {
              if (
                valueHeader[x] != "SI_BOD" &&
                valueHeader[x] != "SI_COD" &&
                valueHeader[x] != "SI_DO_SAT" &&
                valueHeader[x] != "SI_NH3N" &&
                valueHeader[x] != "SI_TSS" &&
                valueHeader[x] != "SI_pH" &&
                valueHeader[x] != "DO_CON" &&
                valueHeader[x] != "DO_SAT"
              ) {
                if (
                  result[j][valueHeader[x]] == "NA" ||
                  result[j][valueHeader[x]] == null
                ) {
                  if (resultFlag[i][valueHeader[x] + "_FLAG"] != null) {
                    result[j][valueHeader[x]] =
                      resultFlag[i][valueHeader[x] + "_FLAG"];
                  }
                }
              } else if (
                valueHeader[x] == "DO_CON" ||
                valueHeader[x] == "DO_SAT"
              ) {
                if (
                  result[j][valueHeader[x]] == "NA" ||
                  result[j][valueHeader[x]] == null
                ) {
                  if (
                    resultFlag[i][
                      valueHeader[x].split("_")[0] +
                        valueHeader[x].split("_")[1] +
                        "_FLAG"
                    ] != null
                  ) {
                    result[j][valueHeader[x]] =
                      resultFlag[i][
                        valueHeader[x].split("_")[0] +
                          valueHeader[x].split("_")[1] +
                          "_FLAG"
                      ];
                  }
                }
              }
            }
          }

          // for(let x in valueHeader){
          //     if (resultFlag[i][valueHeader[x]+"_FLAG"] != null) {
          //         result[j][valueHeader[x]] = resultFlag[i][valueHeader[x]+"_FLAG"];
          //     }
          //     else if (resultFlag[i][valueHeader[x].split("_")[0]+valueHeader[x].split("_")[1]+"_FLAG"] != null) {
          //         result[j][valueHeader[x]] = resultFlag[i][valueHeader[x].split("_")[0]+valueHeader[x].split("_")[1]+"_FLAG"];
          //     }
          // }
        }
      }

      console.log(result);

      result.forEach(function(res) {
        res["datetime_a"] = res["datetime_a"].replaceAll("<br>", ", ");
      });

      // console.log(result);

      var blob = new Blob(
        [Papa.unparse(this.specialExcel(this.dtheader, result))],
        { type: "text/csv;charset=utf-8;" }
      );

      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        "Data For " +
          this.sendStation +
          " From " +
          this.convertDateFormat(this.dateFrom) +
          " To " +
          this.convertDateFormat(this.dateTo) +
          ".csv"
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Get value from Selected Parameter
    // selectParam: function(e) {
    //     // console.log(e);
    //     this.parameterSelected = e;
    //     // console.log(this.parameterSelected);
    // },

    toggleStation() {
      this.$nextTick(() => {
        if (this.checkedAllStations) {
          // console.log(this.stationSelected);
          this.stationSelected = [];
        } else {
          this.stationSelected = this.station.slice();
        }
      });
    },

    toggleParams() {
      this.$nextTick(() => {
        if (this.checkedAllParams) {
          // console.log(this.parameterSelected);
          this.parameterSelected = [];
        } else {
          this.parameterSelected = this.parameter.slice();
        }
      });
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

    //   console.log(index);

      items.sort((a, b) => {
        if (index[0] == "datetime_a") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },

    
  },
  mounted() {
    this.load();
  },
  computed: {
    checkedAllStations() {
      // console.log(this.stationSelected.length);

      this.selectStation();
      return this.stationSelected.length === this.station.length;
      // return this.stationSelected.length === this.parameter.length
    },
    checkedSomeStations() {
      this.selectStation();
      return this.stationSelected.length > 0 && !this.checkedAllStations;
    },
    iconStations() {
      if (this.checkedAllStations) return "mdi-close-box";
      if (this.checkedSomeStations) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    checkedAllParams() {
      // console.log(this.parameterSelected);
      return this.parameterSelected.length === this.parameter.length;
      // return this.parameterSelected.length === this.parameter.length
    },
    checkedSomeParams() {
      return this.parameterSelected.length > 0 && !this.checkedAllParams;
    },
    iconParams() {
      if (this.checkedAllParams) return "mdi-close-box";
      if (this.checkedSomeParams) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
};
</script>

<style lang="scss">
@import "~scss/main";

/* Section Path File/Modul */
.pathModul {
  padding-bottom: 0px;
}

.pathModul > li:nth-child(3) > a {
  color: black !important;
}

/* Section Filtering Input/Select/Button */
.container-filtering {
  padding: 0px;
}

// .container.container-filter{
//     height: calc(100vh - 260px);
// }

.col-input-filter {
  padding: 0px 10px;
}

.col-btn-generateReport {
  margin-bottom: 18px;
}

.btn-generate {
  margin: 0px !important;
}

.btnExportExcelQQ {
  display: none;
}

.v-text-field.v-text-field--solo .v-input__control,
.btn-generate .v-btn .v-btn--contained {
  min-height: 10px;
  margin-top: 10px;
}

/* Generate Table */
.container-result-table {
  display: none;
  // height: calc(100vh - 225px);
}

.subTitle {
  text-align: center !important;
  background: white;
  // color: #1976D2 !important;
  display: none;
}

// .tableQueryOptions > .v-data-table__wrapper > table > thead > tr > th{
//     background: #4495d1 !important;
//     color: white !important;
//     padding: 10px 15px;
// }

.tableQueryOptions > div > table > tbody > tr > td {
  white-space: nowrap !important;
}

/* Generate Chart */
.container-result-chart {
  display: none;
  height: calc(100vh - 225px);
}

/* Footer Report */
.footer-report {
  background: white;
}

// .one-text-footer{
//   margin: 0px 10px;
// }

// Custom certain vue
.tableQueryOptions > .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 480px;
}
</style>